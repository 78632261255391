import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import TreatmentHero from "../../components/treatment-hero";
import TreatmentCard from "../../components/treatment-card";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Faq from "../../components/faq";
import PhotoLeft from "../../components/photo-left";
import { Button } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import ReviewSection from "../../components/review-section";
import { checkPropertiesForNull } from "../../utils/objectHelper";
import { SafeHtmlParser } from "../../components/safe-html-parser";
import uuid4 from "uuid4";

const DentalImplantsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      reviews {
        reviewCount
      }
      schemaLogo: wpMediaItem(title: { eq: "Logo-footer" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: TRACED_SVG
            )
          }
        }
      }

      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "dental-implants" }) {
        slug
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        dentalImplantsFieldGroups {
          dentalImplantsIntroSection {
            heading
            subHeading
            title
            subtitle
            description
            cta {
              title
              target
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                    original {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
          dentalImplantsReviewSection {
            heading
            subHeading
          }
          dentalImplantsServiceSection {
            heading
            subheading
            servicesList {
              title
              description
              image {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]
                        quality: 100
                        transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                        layout: CONSTRAINED
                        placeholder: BLURRED
                      )
                      original {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
          dentalImplantsFaqSection {
            heading
            faqs {
              nodes {
                ... on WpFaq {
                  faqFields {
                    question
                    answer
                  }
                }
              }
            }
          }
          dentalImplantsPricingSection {
            heading
            description
            cta {
              title
              target
              url
            }
            image {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, dentalImplantsFieldGroups, slug },
  } = data;

  const {
    dentalImplantsIntroSection,
    dentalImplantsReviewSection,
    dentalImplantsServiceSection,
    dentalImplantsFaqSection,
    dentalImplantsPricingSection,
  } = dentalImplantsFieldGroups;

  const siteUrl = data.site.siteMetadata.siteUrl;

  const serviceSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "@id": `https://www.dentalhousepinner.co.uk/treatments/${slug}/#${slug}`,
    image: `https://www.dentalhousepinner.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
    mpn: `https://www.dentalhousepinner.co.uk/treatments/${slug}`,
    sku: `https://www.dentalhousepinner.co.uk/treatments/${slug}`,
    description: `${seoFieldGroups?.productSchema}`,
    logo: `https://www.dentalhousepinner.co.uk${data.schemaLogo?.localFile.publicURL}`,
    name: "Dental House Pinner",
    url: "https://www.dentalhousepinner.co.uk",
    brand: {
      "@type": "Organization",
      logo: `https://www.dentalhousepinner.co.uk${data.schemaLogo?.localFile.publicURL}`,
      image: `https://www.dentalhousepinner.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
      name: "Dental House Pinner",
      url: "https://www.dentalhousepinner.co.uk",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      reviewCount: `${data.reviews.reviewCount}`,
      ratingValue: "4.9",
      bestRating: "5",
      worstRating: "1",
    },
    review: {
      "@type": "Review",
      url: "https://www.dentalhousepinner.co.uk",
      datePublished: "2023-08-23",
      reviewBody:
        "As someone terrified of dentists, it's been a pleasure being a patient of Dental House. Dr Shah's been a godsend, a truly welcoming presence who never fails to put you at ease. Clearly explains each procedure and how it should feel. The whole team are great!",
      author: {
        "@type": "Person",
        name: "N R",
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5",
        worstRating: "1",
      },
      publisher: {
        "@type": "Organization",
        name: "Dental House Pinner",
        sameAs: "https://www.dentalhousepinner.co.uk",
      },
    },
  };

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Treatments",
        item: {
          url: `${siteUrl}/treatments`,
          id: `${siteUrl}/treatments`,
        },
      },
      {
        "@type": "ListItem",
        position: "3",
        name: "Dental Implants",
        item: {
          url: `${siteUrl}/treatments/dental-implants`,
          id: `${siteUrl}/treatments/dental-implants`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>
      {/* <LocalBusinessSchema /> */}
      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/treatments/dental-implants`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

      <Layout>
        {dentalImplantsIntroSection &&
          !checkPropertiesForNull(dentalImplantsIntroSection, ["heading"]) && (
            <TreatmentHero
              heading1={dentalImplantsIntroSection?.title}
              smallHeadingTopText={dentalImplantsIntroSection?.subtitle}
              img={
                dentalImplantsIntroSection?.image.node?.localFile
                  .childImageSharp.gatsbyImageData
              }
              imgAlt={dentalImplantsIntroSection?.image.node?.altText}
              heading2={dentalImplantsIntroSection?.heading}
              headingSmall={dentalImplantsIntroSection?.subHeading}
              text={
                <SafeHtmlParser
                  htmlContent={dentalImplantsIntroSection?.description}
                />
              }
              btnLink={dentalImplantsIntroSection?.cta.url}
              btnText={dentalImplantsIntroSection?.cta?.title}
            />
          )}

        <ReviewSection
          heading={
            dentalImplantsReviewSection?.heading ||
            "Reviews From Our Dentistry Patients"
          }
          smallheading={
            dentalImplantsReviewSection?.subHeading ||
            "WHAT PEOPLE SAY ABOUT US"
          }
          page="Dental implants"
        />

        {dentalImplantsServiceSection &&
          !checkPropertiesForNull(dentalImplantsServiceSection, [
            "heading",
          ]) && (
            <section className=" py-5 pb-lg-7">
              <Container>
                <Row>
                  <Col className="text-center">
                    <p className="text-accent jost-bold fs-6">
                      {dentalImplantsServiceSection?.subheading}
                    </p>
                    <h2 className="text-text-secondary display-4 pb-4">
                      {dentalImplantsServiceSection?.heading}
                    </h2>
                  </Col>
                </Row>
                {dentalImplantsServiceSection.servicesList &&
                  dentalImplantsServiceSection.servicesList.length > 0 && (
                    <Row className=" pt-5 h-100 justify-content-center">
                      {" "}
                      {dentalImplantsServiceSection.servicesList.map(
                        (cardItem) => (
                          <TreatmentCard
                            key={uuid4()}
                            extraPadding
                            image={
                              cardItem?.image.node?.localFile.childImageSharp
                                .gatsbyImageData
                            }
                            imgAlt={cardItem?.image.node?.altText}
                            title={cardItem?.title}
                            text={
                              <SafeHtmlParser
                                htmlContent={cardItem?.description}
                              />
                            }
                            span={4}
                            shorter="image-shorter"
                            objPos="top"
                            headingHeight="100px"
                          />
                        )
                      )}
                    </Row>
                  )}
              </Container>
            </section>
          )}

        {dentalImplantsFaqSection &&
          !checkPropertiesForNull(dentalImplantsFaqSection, ["faqs"]) && (
            <Faq
              heading={dentalImplantsFaqSection?.heading}
              faqs={dentalImplantsFaqSection?.faqs.nodes}
            />
          )}
        {dentalImplantsPricingSection &&
          !checkPropertiesForNull(dentalImplantsPricingSection, [
            "heading",
          ]) && (
            <section>
              {" "}
              <PhotoLeft
                imgHeight="40rem"
                img={
                  dentalImplantsPricingSection?.image.node?.localFile
                    .childImageSharp.gatsbyImageData
                }
                imgAlt={dentalImplantsPricingSection?.image.node?.altText}
                text={
                  <div className="text-white text-center text-lg-start">
                    <h2 className="display-4 pb-4">
                      {dentalImplantsPricingSection?.heading}
                    </h2>
                    <GatsbyImage
                      className="mb-4 w-100 d-lg-none"
                      image={
                        dentalImplantsPricingSection?.image.node?.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={dentalImplantsPricingSection?.image.node?.altText}
                    />
                    <SafeHtmlParser
                      htmlContent={dentalImplantsPricingSection?.description}
                    />
                    {dentalImplantsPricingSection &&
                      dentalImplantsPricingSection.cta && (
                        <div>
                          <Button
                            className="text-white mt-lg-4 py-3 px-5 w-100 w-md-auto"
                            variant="accent"
                            href={dentalImplantsPricingSection?.cta.url}
                            target={
                              dentalImplantsPricingSection.cta.target ??
                              "_blank"
                            }
                            rel="noreferrer"
                          >
                            {dentalImplantsPricingSection?.cta.title}
                          </Button>
                        </div>
                      )}
                  </div>
                }
                bgColour="bg-primary"
              />
            </section>
          )}
      </Layout>
    </>
  );
};

export default DentalImplantsPage;
